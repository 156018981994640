<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="optimizingBrand">
      <div class="optimizingBrand-in">
        <b-container class="optimizingBrand-cont" fluid>
          <div class="style">
            <div :class="style_id==''?'style-all-selected':'style-all'" @click="chooseStyle('')">
              <span class="style-txt">全部风格</span>
              <span class="style-eng">All style</span>
            </div>
            <div class="style-item-cont">
              <div
                :class="style_id == item.id?'style-item-selected':'style-item'"
                v-for="(item, index) in styleList"
                :key="index"
                @click="chooseStyle(item.id)"
              >
                <span class="style-txt">{{ item.name }}</span>
                <span class="style-eng">{{ item.engName }}</span>
              </div>
            </div>
          </div>
          <b-row class="classification-pavilion">
            <b-col
              class="brand-pavilion"
              v-for="(item, index) in pavilionNameList"
              :key="index"
              @click="chooseBrandPavilion(item)"
            >
              <div class="brand-pavilion-in">
                <span
                  :class="
                    pavilionTrue == item.id ? 'brandTitleColor' : 'brandTitleDef'
                  "
                >
                  {{ item.name }}
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row class="brand-title-img">
            <b-col class="brand-img-in" sm="6" md="4" lg="4" xl="3">
              <img
                src="@/../public/icon/mall/optimizingBrand/brandsuggest.png"
                alt=""
              />
            </b-col>
          </b-row>
          <b-row class="brand-cont">
            <b-col
              class="brand-item"
              v-for="(item, index) in brandList"
              :key="index"
              sm="6"
              md="4"
              lg="4"
              xl="4"
            >
              <div class="brand-in" @click="toBrandIn(item)">
                <img class="brand-img" :src="item.brand_cover" alt="" />
                <div class="brand-detail">
                  <img :src="item.logo" alt="" />
                  <span>{{ item.brand_miaoshu }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>
<script>
import headerNav from "@/components/Navbar";
import Right from "@/components/Right.vue";
import footerNav from "@/components/Footer";
import dump from "@/util/dump";
export default {
  components: {
    headerNav,
    Right,
    footerNav
  },
  data() {
    return {
      brandList: [], //品牌列表
      styleList: [
      // { name: "侘寂风", engName: "WABI-SABI", id: "720" },
      // { name: "奶油风", engName: "CREAM", id: "721" },
        { name: "极简", engName: "MINIMALIST", id: "16" },
        { name: "侘寂风", engName: "WABI-SABI", id: "720" },
      { name: "奶油风", engName: "CREAM", id: "721" },
        { name: "轻奢", engName: "LIGHT LUXURY", id: "15" },
        { name: "中式", engName: "CHINESE", id: "53" },
        { name: "美式", engName: "AMERICAN", id: "52" },
        { name: "北欧/现代", engName: "NORDIC", id: "54" },
        // { name: "英式", engName: "BRITISH", id: "55" },
        // { name: "欧式/新古典", engName: "EUROPEAN", id: "55" },
        { name: "床垫", engName: "SOFTWARE", id: "643" },
        { name: "户外", engName: "OUTDOOR", id: "644" },
        { name: "儿童", engName: "OTHER", id: "645" },
      ], //风格列表
      style_id: "", //风格id
      pavilionNameList: [
        { name: "品牌馆", id: 1 },
        { name: "本地馆", id: 2 },
      ], //馆分类
      pavilionTrue: 1, //当前馆状态
      city_id: "", //城市id
    };
  },
  created() {
    this.getBrandsData();
  },
  methods: {
    //获取品牌数据
    getBrandsData() {
      const that = this;
      let para = {
        brand_house: 1,
        style_id: that.style_id,
        shop_id: that.cookie.getCookie("shopId")
      };
      that.$api.getbrands(para).then((res) => {
        if (res.status == 100) {
          that.brandList = res.data.brands;
        }
      });
    },
    //获取本地馆品牌数据
    getLocalData() {
      const that = this;
      let para = {
        brand_house: 2,
        style_id: that.style_id,
        city_id: that.city_id,
      };
      that.$api.getlocal(para).then((res) => {
        if (res.status == 100) {
          that.brandList = res.data.brands;
        }
      });
    },
    //选择品牌
    chooseBrandPavilion(item) {
      const that = this;
      if (item.id == 1) {
        that.pavilionTrue = item.id;
        that.getBrandsData();
      } else {
        that.pavilionTrue = item.id;
        that.getLocalData();
      }
    },
    //选择风格
    chooseStyle(id){
        const that = this
        that.style_id = id
        if(that.pavilionTrue == 1){
            that.getBrandsData()
        }else{
            that.getLocalData()
        }   
    },
    //
    toBrandIn(obj){
      dump.link({
        type: 3,
        link: "/brandDetails",
        params: {
          brand_id: obj.brand_id,
          brand_type: obj.brand_type
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/view/mall/optimizingBrand/optimizingBrand";
</style>